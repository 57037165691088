/* App.css */
body {
  font-family: 'Readex Pro', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #1e1e2f; /* Dark navy/blue background */
  color: #e0e0e0; /* Light text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hideAttributions {
  display: none;
}