
/* Main container for the application */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: bold;
}

/* Container for the tabs */
.tabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: #f1f1f1;
  margin-bottom: 10px; /* Space below the tabs */
}

/* Individual tab buttons */
.tabButtons {
  flex: 1;
  border: 5px solid #3a3a4f;
  font-weight: bold;
  color: #e0e0e0; /* Black text color */
  background-color: #3a3a4f;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
  justify-content: center;
  display: inline-flex;
  align-items: center; /* Align items vertically center */
  border-radius: 0; /* Ensure no rounded corners for a clean look */
  margin: 0; /* Remove default margin */
  position: relative; /* Needed for the active bottom border */
}

.tabButtons:hover {
  filter: brightness(120%);
}

/* Active tab button */
.tabButtons.active {
  background-color: #3a3a4f;
  color: #e0e0e0;
  border-bottom: 5px solid #e0e0e0; /* Highlight with bottom border */
}

/* Icon inside the button */
.tabIcon {
  margin-left: 8px; /* Space between text and icon */
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */

}

/* Icon inside the button */
.tabButtons img.nfl {
  margin-right: 20px; /* Space between text and icon */
  width: auto;
  height: 3em; /* Adjust icon size to fit the button */
  vertical-align: middle; /* Ensure the icon aligns with the text */
  background: none; /* Ensure no background is applied */
  padding: 0; /* Remove any padding */
  border: none; /* Remove any border */
}

/* Icon inside the button */
.tabButtons img.mlb {
  margin-right: 20px; /* Space between text and icon */
  width: auto;
  height: 2em; /* Adjust icon size to fit the button */
  vertical-align: middle; /* Ensure the icon aligns with the text */
  background: none; /* Ensure no background is applied */
  padding: 0; /* Remove any padding */
  border: none; /* Remove any border */
}