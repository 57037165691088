/* CSS Animation for fading out and sliding left */
@keyframes fadeOutSlideLeft {
  from {
    opacity: 1;
    transform: translateX(0);
    height: auto;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #333;
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
  }
}

/* Filter Button Styles */
.filter-buttons button {
  margin: 0 5px;
  padding: 5px 10px;
  color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Readex Pro', sans-serif;
}

.player-list {
  width: 95%;
  margin: auto;
  padding-bottom: 100px; /* Adjust this value as needed */
  overflow-x: auto;
  overflow: visible; /* Ensure content can overflow */

}

.player-list-header,
.player-card {
  display: grid;
  grid-template-columns: 2em 6em 1fr 4em; /* Adjust the number of columns */
  gap: 10px;
  align-items: center;
  padding: 10px;
  border: 1px solid #333; /* Darker border color */
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden; /* Ensure height transition works smoothly */
  transition: opacity 1.5s ease-out, height 1.5s ease-out, transform 1.5s ease-out;
  height: auto;
  overflow: visible; /* Ensure content can overflow */

}

.player-card.drafted {
  animation: fadeOutSlideLeft 1.5s forwards; /* Update the animation to use the new keyframes */
  /* height: 5px; */
  display: none;
}

.player-card:hover {
  filter: brightness(110%)
}


.player-list-header {
  font-weight: bold;
  background-color: #3a3a4f; /* Darker header background */
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
  text-align: center;
}

.player-card {
  background-color: #2a2a3b; /* Darker card background */
  cursor: grab; /* Ensure draggable cursor */
}

.player-card:active {
  cursor: grabbing;
}

.overall-rank, .player-adp, .player-projected {
  font-weight: normal;
  font-family: 'Chakra Petch', sans-serif; /* Apply Chakra Petch font here */
  font-size: large;
}

.overall-rank{
  text-align: center;
}

.player-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translate(0em, 0.75em);
}

.player-name {
  font-weight: bold;
  font-size: small;
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

.player-details {
  display: flex;
  flex-direction: column; /* Change to column to stack items vertically */
  /* align-items: center; */
  transform: translate(0em, -1em);
  font-size: 0.6em; /* Even smaller font size for team, position rank, and bye */
  color: #a0a0a0; /* Light gray for details */
}

.player-position {
  border: 1px solid #ccc;
  padding: 3px 1px;
  border-radius: 4px;
  font-weight: bold;
  color: black; /* Black text color */
}

/* Define the common subclass for all sub positions */
.subPositions {
  font-size: 1em; /* Example style */
  width: 2em;
  text-align: center;
  margin-left: 1em;
}

.team-position-container {
  display: flex;
  align-items: center; /* Align items in the center vertically */
}


.subPositions-container {
  display: flex;
  flex-wrap: wrap; /* Allow sub positions to wrap if there are many */
  margin-top: 10px; /* Add more space above the sub positions */
  justify-content: flex;
}

.team-logo {
  max-height: 20px; /* Adjust to desired maximum height */
  max-width: 20px; /* Adjust to desired maximum width */
  width: auto;
  height: auto;
  margin-right: 5px;
}

.player-position{
  width: 4em;
  text-align: center;
}

.player-position.QB {
  background-color: #ef74a1; /* Pink */
  border: 1px solid #ef74a1;
}

.player-position.RB {
  background-color: #8ff2ca; /* Green */
  border: 1px solid #8ff2ca;
}

.player-position.WR {
  background-color: #56c9f8; /* Blue */
  border: 1px solid #56c9f8;
}

.player-position.TE {
  background-color: #feae58; /* Orange */
  border: 1px solid #feae58;
}

.player-position.K {
  background-color: #b6b9ff; /* Purple */
  border: 1px solid #b6b9ff;
}

.player-position.DST {
  background-color: #d3d3d3; /* Gray */
  border: 1px solid #d3d3d3;
}

.position.QB {
  background-color: #ef74a1; /* Pink */
}

.position.RB {
  background-color: #8ff2ca; /* Green */
}

.position.WR {
  background-color: #56c9f8; /* Blue */
}

.position.TE {
  background-color: #feae58; /* Orange */
}

.position.K {
  background-color: #b6b9ff; /* Purple */
}

.position.DST {
  background-color: #d3d3d3; /* Gray */
}

.position.FLEX {
  background: linear-gradient(90deg, #8ff2ca 33%, #56c9f8 33%, #56c9f8 66%, #feae58 33%);
}

.position.QB,
.position.RB,
.position.WR,
.position.TE,
.position.K,
.position.DST,
.position.FLEX,
.position.FAV,
.position.SP,
.position.RP,
.position.CATCH,
.position.B1,
.position.B2,
.position.B3,
.position.SS,
.position.OF,
.position.DH,
.position.PITCHERS,
.position.BATTERS, 
.position.PG,
.position.SG,
.position.SF,
.position.PF,
.position.C,
.position.GUARDS,
.position.FORWARDS,
button {
  border: 1px solid #3b3b3b;
  text-align: center;
  padding: 2px 5px;
  border-radius: 4px;
  width: 4em;
  font-weight: bold;
  color: #3b3b3b; /* Black text color */
}

.position.QB:hover,
.position.RB:hover,
.position.WR:hover,
.position.TE:hover,
.position.K:hover,
.position.DST:hover,
.position.FLEX:hover,
.position.FAV:hover,
.position.SP:hover,
.position.RP:hover,
.position.CATCH:hover,
.position.B1:hover,
.position.B2:hover,
.position.B3:hover,
.position.SS:hover,
.position.OF:hover,
.position.DH:hover,
.position.PITCHERS:hover,
.position.BATTERS:hover,
.position.PG:hover,
.position.SG:hover,
.position.SF:hover,
.position.PF:hover,
.position.C:hover,
.position.GUARDS:hover,
.position.FORWARDS:hover,
button:hover {
  filter: brightness(85%);
}

.filter.FAV.OFF:hover, .filter.ROOKIE.OFF:hover {
  filter:brightness(120%)
}

.filter.FAV.ON:hover, .filter.ROOKIE.ON:hover{
  filter:brightness(85%)
}

.filter.FAV.OFF {
  background-color: #3a3a4f; /* Gray */
  color: #f0e68c; /* Black text color */
  border: 1px solid #f0e68c;
}

.filter.ROOKIE.OFF {
  background-color: #3a3a4f; /* Gray */
  color: lightcoral; /* Black text color */
  border: 1px solid lightcoral;
}

.filter.FAV.ON {
  background-color: #f0e68c; /* Gray */
  color: #3a3a4f; /* Black text color */
  border: 1px solid #3a3a4f;
}

.filter.ROOKIE.ON {
  background-color: lightcoral; /* Gray */
  color: #3a3a4f; /* Black text color */
  border: 1px solid #3a3a4f;
}

.player-additional-info {
  font-size: smaller;
}

.reset-button.RESET {
  color: #e04f5f; /* Black text color */
  
}



.position-ALL {
  background-color: #3a3a4f;
  width: 4em;
}

.player-list-header span {
  color: #e0e0e0; /* Light text color */
}

.player-team {
  margin-right: 1em;
  margin-left: 0.5em;
  width: 2em;
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

.player-bye {
  margin-left: 1em; /* Add spacing between position rank and bye */
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

.player-adp, .player-projected {
  text-align: center;
}
.playerNameOnly {
  text-align: left;
}

.rookie-flag {
  color: lightcoral;
  font-weight: bold;
  font-size: 0.8em;
  margin-left: 5px;
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

.favorite-button {
  background: none;
  border: none; /* Add border */
  cursor: pointer;
  font-size: 1.5em;
  color: #ccc;
  margin-left: 8em;
  display: flex;
  justify-content: center;
  width: auto; /* Ensure consistent size */
  height: 1em; /* Ensure consistent size */
}

.favorite-button.selected {
  color: #f0e68c; /* Pale highlighter yellow shade */
  border-color: #f0e68c; /* Change border color when selected */
}


.favorite-button img {
  width: 100%; /* Ensure image fits the button */
  height: 100%; /* Ensure image fits the button */
  transition: filter 0.3s ease, drop-shadow 0.3s ease;
  object-fit: contain;
}

.favorite-button img:hover {
  filter: drop-shadow(0 0 10px #f0e68c); 
}

.filter-export-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.filter-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap */
  gap: 10px;
  justify-content: center; /* Center the buttons */
  margin: auto; /* Ensure it centers within its parent */
  margin-bottom: 1em;
}

.export-import-buttons {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically center */
  width: 100%;
  padding-top: 10px;
  margin-bottom: 2em;
}

.export-button {
  text-align: center; /* Center text inside the buttons */
}

.reset-button {
  text-align: center;
}

.reset-button,
.import-button,
.export-button {
  padding: 5px 10px;
  background-color: #3a3a4f;
  color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

.reset-button {
  width: auto;
}

.export-button, .import-button {
  margin-right: 10px; /* Optional: adds space between buttons */
  width: 12em;
}

.delete-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-icon {
  width: 1.25em; /* Adjust size as needed */
  height: 1.25em; /* Adjust size as needed */
}

.delete-button:hover .delete-icon {
  filter: brightness(0.75); /* Darken icon on hover */
}

.export-button:hover,
.import-button:hover,
.reset-button:hover,
.position-ALL:hover {
  filter: brightness(120%);
}


@media (max-width: 768px) {
  .player-list-header, .player-card {
    grid-template-columns: 2em 6em 1fr 4em; /* Adjust the number of columns */
    font-size: 0.8em; /* Reduce font size */
    padding: 5px; /* Reduce padding */
    transition: opacity 1s ease-out, height 1s ease-out, transform 1s ease-out;
  }

  .favorite-button {
    background: none;
    border: none; /* Add border */
    cursor: pointer;
    font-size: 1.5em;
    color: #ccc;
    margin-left: 10em;
    display: flex;
    justify-content: center;
    width: auto; /* Ensure consistent size */
    height: 20px; /* Ensure consistent size */
  }

  .player-info {
    font-size: 0.8em; /* Reduce font size for player info */
    transform: translate(0em, 0.5em);

  }

  .filter-buttons button, .export-import-buttons button {
    padding: 3px 5px; /* Reduce button padding */
    font-size: 0.8em; /* Reduce button font size */
  }
}

/*  MLB STUFF */
/* New Positions for MLB */
.position.SP {
  background-color: #c96cff;
}

.position.RP {
  background-color: #b6b9ff;
}

.position.CATCH {
  background-color: #d3d3d3;
}

.position.B1 {
  background-color: #8ff2ca;
}

.position.B2 {
  background-color: #00ced1;
}

.position.SS {
  background-color: #56c9f8;
}

.position.B3 {
  background-color: #c6f87c;
}

.position.OF {
  background-color: #feae58;
}

.position.DH {
  background-color: #ef74a1;
}

.position.PITCHERS {
  background: linear-gradient(to right, #c96cff 50%, #b6b9ff 50%);
}

.position.BATTERS {
  background: linear-gradient(to right, 
      #acacac 14.28%, 
      #8ff2ca 14.28% 28.56%, 
      #00ced1 28.56% 42.84%, 
      #56c9f8 42.84% 57.12%, 
      #c6f87c 57.12% 71.40%, 
      #feae58 71.40% 85.68%, 
      #f36363 85.68% 100%);
}

.player-position.SP {
  background-color: #c96cff;
  border: 1px solid #c96cff;

}

.player-position.RP {
  background-color: #b6b9ff;
  border: 1px solid #b6b9ff;
}

.player-position.CATCH {
  background-color: #d3d3d3; 
  border: 1px solid #d3d3d3;
}

.player-position.B1 {
  background-color: #8ff2ca; 
  border: 1px solid #8ff2ca;
}

.player-position.B2 {
  background-color: #00ced1;
  border: 1px solid #00ced1;
}

.player-position.B3 {
  background-color: #c6f87c;
  border: 1px solid #c6f87c;
}

.player-position.SS {
  background-color: #56c9f8;
  border: 1px solid #56c9f8;
}

.player-position.OF {
  background-color: #feae58;
  border: 1px solid #feae58;
}

.player-position.DH {
  background-color: #ef74a1;
  border: 1px solid #ef74a1;
}

.position.PG {
  background-color: #ef74a1; /* Pink */
}

.position.SG {
  background-color: #feae58; /* Orange */
}

.position.SF {
  background-color: #8ff2ca; /* Green */
}

.position.PF {
  background-color: #56c9f8; /* Blue */
}

.position.C {
  background-color: #b6b9ff; /* Purple */
}

.position.GUARDS {
  background: linear-gradient(90deg, #ef74a1 50%, #feae58 50%);
}

.position.FORWARDS {
  background: linear-gradient(90deg, #8ff2ca 50%, #56c9f8 50%);
}

.player-position.PG {
  background-color: #ef74a1; /* Pink */
  border: 1px solid #ef74a1;
}

.player-position.SG {
  background-color: #feae58; /* Orange */
  border: 1px solid #feae58;
}

.player-position.SF {
  background-color: #8ff2ca; /* Green */
  border: 1px solid #8ff2ca;
}

.player-position.PF {
  background-color: #56c9f8; /* Blue */
  border: 1px solid #56c9f8;
}

.player-position.C {
  background-color: #b6b9ff; /* Purple */
  border: 1px solid #b6b9ff;
}
