/* Spinner.css */
.spinner {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  padding-top: 8em;
  
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 20px; /* Space between spinner and text */
  font-size: 1.5em; /* Increase font size slightly */
  color: #e0e0e0; /* Optional: Change text color */
  font-family: 'Readex Pro', sans-serif; /* Apply Readex Pro font */
}

