.App-header {
  font-family: 'Readex Pro', sans-serif;
  font-size: larger;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e2f; /* Dark navy/blue background */
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #e0e0e0; /* Light text color */
  position: relative;

}

.title-container {
  flex: 1;
  border-bottom: 2px solid #e0e0e0; /* Light text color for the border */
  padding-bottom: 10px; /* Adds some space between the text and the border */
}

.App-header h1 {
  margin: 0;
  color: #e0e0e0;
}

.App-header h2 {
  margin: 1em;
  font-size: x-small;
  color: #e0e0e0;
}


.button-container {
  position: absolute;
  right: 20px;
}