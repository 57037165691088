/* Footer.css */

.App-footer {
  font-family: 'Readex Pro', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #1e1e2f; /* Dark navy/blue background */
  color: #e0e0e0; /* Light text color */
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other elements */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.footer-text {
  text-align: center;
}

.footer-text h1 {
  color: #e0e0e0;
  font-size: medium;
}

.footer-text h2 {
  color: #e0e0e0;
  font-weight: 300;
  font-size: xx-small;
}

.info-button {
  background-color: transparent;
  font-family: 'Readex Pro', sans-serif;
  color: #e0e0e0;
  border: none;
  cursor: pointer;
  font-size: small;
}

.backtotop-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.backtotop-button img {
  width: 75%;
  height: auto;
  border-radius: 50%;
  transition: background-color 0.3s, box-shadow 0.3s;
  opacity: 25%;
}

.backtotop-button:hover img {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  opacity: 100%;
}

/* Modal content styles */
.ReactModal__Content {
  color: #1e1e2f !important;
  /* color: #e0e0e0 !important; */
  background-color: #e0e0e0 !important;
  /* background-color: #1e1e2f !important; */
  filter:opacity(100%) !important;
  inset: 1em !important;
  max-height: calc(100vh - 9em) !important; /* Allows the content to take up to the full viewport height minus some padding */
  overflow-y: auto !important; /* Enables vertical scrolling if content exceeds the available height */

}
/* Additional styles for close button or other elements inside the modal */
.ReactModal__Content button {
  border: none; /* Remove border */
  color: #1e1e2f; /* White text for buttons */
  cursor: pointer; /* Pointer cursor for buttons */
  font-size: xx-large; /* Increase font size */
  font-family: 'Readex Pro', sans-serif; 
  font-weight: normal;
  width: 10em;
  background-color: #e0e0e0;
  /* opacity: 0%; */
}

.ReactModal__Content button:hover {
  filter:brightness(97%)
}

.ReactModal__Overlay {
  background-color: rgba(30, 30, 47, 75%) !important;
  display: flex !important; /* Use flexbox layout */
  justify-content: center !important; /* Center items horizontally */
  align-items: center !important; /* Center items vertically */
  height: 100vh !important; /* Ensure the container takes up the full viewport height (optional) */
}

.closeButton-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  margin-top: 3em;
}

li {
  margin-bottom: 0.5em;
}

.footer-attributions {
  display: inline-block;
  cursor: pointer;
  font-size: small;
  background-color: #e0e0e0;
  /* color: blue; */
  /* text-decoration: underline; */
}

.footer-attributions:hover {
  filter:brightness(97%)
}

.attributions-Container {  
  text-align: right;
}

.tinyText {
  font-size: xx-small;
}