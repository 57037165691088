.message-container {
  width: 100%; /* Full width of the screen */
}

.message-box {
  margin: 20px auto; /* Space from other parts */
  padding: 0em 1em; /* Space it takes up */
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Readex Pro', sans-serif;
  color: #333;
  position: relative;
  width: 100%; /* Takes up the entire width of the container */
  box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

.message-content-wrapper {
  display: flex;
  align-items: center; /* Aligns the button and text vertically */
  justify-content: space-between;
}

.message-box.info {
  border-left: 4px solid #007bff;
  border-right: 4px solid #007bff;
  background-color: #e7f3ff;
}

.message-box.success {
  border-left: 4px solid #28a745;
  border-right: 4px solid #28a745;
  background-color: #e6ffe6;
}

.message-box.warning {
  border-left: 4px solid #ffc107;
  border-right: 4px solid #ffc107;
  background-color: #fff9e6;
}

.message-box.error {
  border-left: 4px solid #dc3545;
  border-right: 4px solid #dc3545;
  background-color: #ffe6e6;
}

.message-content {
  margin: 0;
  font-size: 1em;
  line-height: 1.4;
}

.close-button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #3b3b3b;
  transition: color 0.3s ease;
  width: auto;
  /* margin-left: %; Pushes the button to the far right */
}

.close-button:hover {
  color: #000;
}
