.tooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  pointer-events: none;
  position: absolute;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Hover effect to show tooltip */
.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: .4;
}

/* Positioning the tooltip to the right and below */
.tooltipText.RIGHTDOWN {
  top: 100%; /* Below the container */
  left: 50%; /* Center horizontally */
}

.tooltipText.LEFTDOWN {
  top: 100%; /* Below the container */
  left: 10%; /* Adjusted to be slightly to the left */
  transform: translateX(-100%); /* Adjust the horizontal shift */
}

